import Sponsor1 from './icons/sponsors/sponsor-1.png';
import Sponsor2 from './icons/sponsors/sponsor-2.png';
import Sponsor3 from './icons/sponsors/sponsor-3.png';
import Sponsor4 from './icons/sponsors/sponsor-4.png';
import Sponsor5 from './icons/sponsors/sponsor-5.png';
import Sponsor6 from './icons/sponsors/sponsor-6.png';
import Sponsor7 from './icons/sponsors/sponsor-7.png';
import Sponsor8 from './icons/sponsors/sponsor-8.png';
import Sponsor9 from './icons/sponsors/sponsor-9.png';

let sponsors = [
    Sponsor6,
    Sponsor1,
    Sponsor3,
    Sponsor4,
    Sponsor5,
    Sponsor7,
    Sponsor8,
    Sponsor9,
    Sponsor2,
]

export default sponsors;