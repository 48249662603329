import React, {useEffect, useState} from "react";
import useScreenSize from "../../hooks/useScreenSize";

export default function OfferBack({offerDems}) {
    const [styles, setStyles] = useState({})
    const {isMobile} = useScreenSize();

    useEffect(() => {
        setStyles({
            right: {
                transform: `translate(${isMobile ? 20 : 80}px, ${isMobile ? -45 : -25}px)`,
            },
            line: {
                display: isMobile ? "none" : "block",
                width: "100%"
            },
            rightTitle: {
                transform: `translate(${offerDems.x + (isMobile ? 20 : -90)}px, ${offerDems.y + (isMobile ? -5 : 0)}px)`,
                width: isMobile ? "49px" : "90px"
            },
            smallLine: {
                display: !isMobile ? "none" : "block",
                width: "100%"
            }
        })
    }, [isMobile, offerDems.x, offerDems.y])

    return [
        <svg xmlns="http://www.w3.org/2000/svg" width="201" height="70"
             key="right"
             style={styles.right}
             viewBox="0 0 201 70" fill="none">
            <path fillRule="evenodd" clipRule="evenodd"
                  d="M164.823 68.6972C162.965 66.9108 162.907 63.9566 164.694 62.0988L190.759 35L164.694 7.90118C162.907 6.04336 162.965 3.08916 164.823 1.30279C166.682 -0.483582 169.637 -0.425659 171.424 1.43217L199.045 30.1482C201.652 32.858 201.652 37.142 199.045 39.8518L171.424 68.5678C169.637 70.4257 166.682 70.4836 164.823 68.6972ZM123.976 68.6972C122.117 66.9108 122.059 63.9566 123.846 62.0988L149.912 35L123.846 7.90118C122.059 6.04336 122.117 3.08916 123.976 1.30279C125.834 -0.483582 128.789 -0.425659 130.576 1.43217L158.198 30.1482C160.804 32.858 160.804 37.142 158.198 39.8518L130.576 68.5678C128.789 70.4257 125.834 70.4836 123.976 68.6972ZM82.9985 62.0988C81.2115 63.9566 81.2694 66.9108 83.1279 68.6972C84.9864 70.4836 87.9416 70.4257 89.7286 68.5678L117.35 39.8518C119.956 37.142 119.956 32.858 117.35 30.1482L89.7286 1.43217C87.9416 -0.425659 84.9864 -0.483582 83.1279 1.30279C81.2694 3.08916 81.2115 6.04336 82.9985 7.90118L109.064 35L82.9985 62.0988ZM42.2803 68.6972C40.4218 66.9108 40.3639 63.9566 42.1509 62.0988L68.2165 35L42.1509 7.90118C40.3639 6.04336 40.4218 3.08916 42.2803 1.30279C44.1388 -0.483582 47.094 -0.425659 48.881 1.43217L76.5023 30.1482C79.1087 32.858 79.1087 37.142 76.5023 39.8518L48.881 68.5678C47.094 70.4257 44.1388 70.4836 42.2803 68.6972ZM1.30324 62.0988C-0.483765 63.9566 -0.425812 66.9108 1.43266 68.6972C3.29114 70.4836 6.24637 70.4257 8.03336 68.5678L35.6546 39.8518C38.2611 37.142 38.2611 32.858 35.6546 30.1482L8.03336 1.43217C6.24637 -0.425659 3.29114 -0.483582 1.43266 1.30279C-0.425812 3.08916 -0.483765 6.04336 1.30324 7.90118L27.3689 35L1.30324 62.0988Z"
                  fill="#CCF304"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg"
             key="line"
             style={styles.line}
             viewBox="0 0 1280 252" fill="none">
            <path vectorEffect="non-scaling-stroke"
                  d="M-112.747 209.442C-28.772 99.0478 6.95087 134.106 68.3084 213.716C104.036 260.073 156.494 276.315 220.701 182.304C275.204 102.5 364.846 146.277 396.384 186.451C456.789 263.399 552.565 217.646 602.977 158.819C653.389 99.9918 725.964 26.7989 843.606 60.9698C926.897 85.163 985.037 162.662 1149.96 126.875C1314.89 91.087 1397.38 -26.4861 1386.2 -185.041"
                  stroke="#CCF304" strokeWidth="2"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg"
             key="small-line"
             style={styles.smallLine}
             viewBox="0 0 375 165" fill="none">
            <path vectorEffect="non-scaling-stroke"
                  d="M-109.045 122.007C-50.0626 8.82453 -25.2745 44.5319 17.2297 125.693C41.9796 172.952 78.5103 189.359 123.643 93.0013C161.955 11.2056 224.321 55.6241 246.171 96.5778C288.021 175.019 354.996 127.881 390.376 67.5467C425.757 7.21246 476.65 -67.8926 558.582 -33.4015C616.591 -8.98148 656.858 70.0326 772.031 32.8209C887.204 -4.39082 945.175 -124.907 937.964 -286.968"
                  stroke="#CCF304" strokeWidth="2"/>
        </svg>,
        <svg xmlns="http://www.w3.org/2000/svg"
             width="90" height="35" viewBox="0 0 90 35"
             key="right-title"
             style={styles.rightTitle}
             fill="none">
            <path d="M90 17.5L60 35L60 17.5V3.8147e-06L90 17.5Z" fill="#8420E8"/>
            <path d="M30 17.5V35L60 17.5L30 3.8147e-06L30 17.5Z" fill="#8420E8"/>
            <path d="M30 17.5L0 3.8147e-06V35L30 17.5Z" fill="#8420E8"/>
        </svg>
    ]
}